<template>
  <el-card shadow="never">
    <el-table :data="profiles"
      :empty-text="$t('connections.no-matching-providers')"
      show-header
      class="hide-table-header"
      @row-click="goToMarketplaceProfile">
      <!-- Company Logo -->
      <el-table-column width="220"
        class-name="marketplace-table-image">
        <template #default="{row}">
          <img :src="row._logo"
            :alt="$t('images.marketplace-logo')"
            class="marketplace-logo"
            @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">
        </template>
      </el-table-column>

      <!-- Company Name and Bio -->
      <el-table-column class-name="marketplace-table-details">
        <template #default="{row}">
          <h4 class="color-text-primary"
            :data-company-name="row.companyName">
            {{ row.companyName }}
            <i class="el-icon-info info-link-button-table"
              aria-hidden="true" />
          </h4>

          <p>{{ row.companyBio || $t('target-select.no-bio') }}</p>
        </template>
      </el-table-column>

      <!-- Destination/Location Count -->
      <el-table-column width="120"
        class-name="marketplace-table-ports">
        <template #default="{row}">
          <!-- Destination Count -->
          <p class="color-text-primary font-weight-500 text-align-center m-0 fs-2-2rem">
            {{ row.destinationCount }}
          </p>
          <p class="color-text-regular text-align-center m-0">
            {{ $tc('marketplace.pluralize-destinations', row.destinationCount) }}
          </p>

          <hr>

          <!-- Location Count -->
          <p class="color-text-primary font-weight-500 text-align-center m-0 fs-2-2rem">
            {{ row.locationCount }}
          </p>
          <p class="color-text-regular text-align-center m-0">
            {{ $tc('marketplace.pluralize-locations', row.locationCount) }}
          </p>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import { mapState } from 'vuex'
import { setFallbackImage } from '@/utils/fallbackImage'

export default {
  name: 'BoxViewCard',

  props: {
    profiles: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState('Marketplace', ['marketplaceData']),
  },

  methods: {
    setFallbackImage,
    /**
     * Redirects to the marketplace profile page for the given row.
     * @param {string} row Row data for the clicked profile.
     */
    goToMarketplaceProfile(row) {
      this.$router.push(row.link, () => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.info-link-button-table {
  color: var(--color-primary);
  font-size: 1.6rem;
  margin-left: 0.5rem;

  &:hover {
    color: var(--color-success);
  }
}

.marketplace-logo {
  width: 200px;
  height: 200px;
}

.fs-2-2rem {
  font-size: 2.2rem;
}

::v-deep {
  .el-table--enable-row-hover
  .el-table__body
  tr {
    cursor: pointer;

    .marketplace-table-ports {
      background-color: var(--color-info-light-8);
      text-align: center;

      hr {
        border-bottom-color: var(--color-info-light-6);
      }
    }

    &:hover {
      .marketplace-table-image {
        background-color: white;
      }

      .marketplace-table-ports {
        background-color: var(--color-info-light-7);

        hr {
          border-bottom-color: var(--color-info-light-5);
        }
      }
    }
  }

  .marketplace-table-details {
    min-height: 200px;
    vertical-align: top;

    p {
      white-space: pre-line;
      line-height: 1.375em;
      word-break: normal;
    }
  }
}
</style>
