<template>
  <el-card :data-company-name="profile.companyName"
    shadow="hover"
    class="position-relative">
    <template #header>
      <h4>
        <router-link :to="profile.link"
          class="unstyled-link">
          {{ profile.companyName }}
        </router-link>
      </h4>
    </template>

    <router-link :to="profile.link"
      class="unstyled-link">
      <img :src="profile._logo"
        :alt="$t('images.marketplace-logo')"
        loading="lazy"
        class="marketplace-img"
        @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">

      <p>{{ $tc('marketplace.destinations-count', profile.destinationCount, { count: profile.destinationCount }) }}</p>
      <p>{{ $tc('general.locations-count', profile.locationCount, { count: profile.locationCount }) }}</p>
      <i class="el-icon-info info-link-button"
        aria-hidden="true" />
    </router-link>
  </el-card>
</template>

<script>
import { mapState } from 'vuex'
import { setFallbackImage } from '@/utils/fallbackImage'

export default {
  name: 'BoxViewCard',

  props: {
    profile: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('Marketplace', ['marketplaceData']),
  },

  methods: {
    setFallbackImage,
  },
}
</script>

<style lang="scss" scoped>
.info-link-button {
  color: var(--color-primary);
  font-size: 1.7rem;
  position: absolute;
  bottom: 2rem;
  right: 2rem;

  &:hover {
    color: var(--color-success);
  }
}

p {
  margin: 0;
}

.marketplace-img {
  width: 200px;
  height: 200px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 1rem;
}
</style>
